import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-11-03 17:53:12
 * @Description: 分類篩選
 */
import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { productListBaseFilter, productListParams, useSearchProductList } from '@common/application/store/product-list';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useContext, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MobileFilterContext } from './mobile-filter-context';
export const CategoryFilter = ({ onChange, blackLineIcon, iconArrow }) => {
    const { merchantDisplayCategories } = useRecoilValue(productListBaseFilter);
    const [filter, setFilter] = useRecoilState(productListParams);
    const { search } = useSearchProductList();
    const { pc } = useBreakpoint();
    const filterContext = useContext(MobileFilterContext);
    const [expanded, setExpanded] = useState();
    const [subExpanded, setSubExpanded] = useState();
    const { t } = useTranslation(['product-list']);
    const handleClick = (value, type) => {
        if (type === 'frist') {
            setExpanded({ [value.id]: true });
        }
        else if (type === 'second') {
            setSubExpanded(() => ({ [value.id]: true }));
        }
        setFilter((org) => (Object.assign(Object.assign({}, org), { categoryIds: value.id })));
        if (pc) {
            search({ filterType: 'price' });
        }
        if (onChange)
            onChange(value);
    };
    return (_jsxs(_Fragment, { children: [!pc && (_jsxs("div", Object.assign({ "data-testid": "mobile-merchant-category", className: classnames('flex justify-between items-center h-12') }, { children: [_jsx("div", Object.assign({ className: classnames('text-base text-black-1') }, { children: t('goodsCategory') })), _jsx("div", Object.assign({ onClick: filterContext.closeDrawer }, { children: blackLineIcon }))] }))), _jsx("div", Object.assign({ className: classnames('mb-0', 'pc:mb-3') }, { children: merchantDisplayCategories &&
                    merchantDisplayCategories.length > 0 &&
                    (merchantDisplayCategories[0].childTree || []).map((item) => {
                        const childIds = (item.childTree || []).map((child) => child.id);
                        const selectParent = filter.categoryIds === item.id || childIds.indexOf((filter === null || filter === void 0 ? void 0 : filter.categoryIds) || '') > -1;
                        return (_jsxs("div", { children: [_jsxs("div", Object.assign({ "data-testid": "merchant-category-first", className: classnames('flex items-center gap-2 text-sm justify-between cursor-pointer border-b border-black-6', 'h-11', 'pc:h-12', { '!border-0': selectParent }), onClick: () => handleClick(item, 'frist') }, { children: [_jsx("span", Object.assign({ className: classnames('text-black-2 font-medium line-clamp-1', 'text-sm', 'pc:text-base', {
                                                'text-primary': filter.categoryIds === item.id
                                            }) }, { children: item.name })), item.childTree.length > 0 ? (_jsx("div", Object.assign({ "data-testid": "merchant-category-first-expand", onClick: (e) => {
                                                e.stopPropagation();
                                                setExpanded((val) => ({ [item.id]: val ? !val[item.id] : true }));
                                                setSubExpanded(undefined);
                                            } }, { children: iconArrow && iconArrow(expanded ? expanded[item.id] : false) }))) : (_jsx("div", { className: "w-6 h-6 invisible" }))] })), _jsx("div", Object.assign({ className: classnames('hidden bg-[#F5F5F5]', '-mx-5 px-5', 'pc:-mx-4 pc:px-4', {
                                        '!block': expanded ? expanded[item.id] : false
                                    }) }, { children: (item.childTree || []).map((sub) => {
                                        return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classnames('flex items-center justify-between gap-2 text-sm cursor-pointer', 'h-8.5', 'pc:h-10'), "data-testid": "merchant-category-second", onClick: () => handleClick(sub, 'second') }, { children: [_jsx("span", Object.assign({ className: classnames('text-black-2 font-medium line-clamp-1', 'text-1xs', 'pc:text-sm', {
                                                                'text-primary': filter.categoryIds === sub.id
                                                            }) }, { children: sub.name })), sub.childTree.length > 0 ? (_jsx("div", Object.assign({ "data-testid": "merchant-category-second-expand", onClick: (e) => {
                                                                e.stopPropagation();
                                                                setSubExpanded((val) => ({ [sub.id]: val ? !val[sub.id] : true }));
                                                            } }, { children: iconArrow && iconArrow(subExpanded ? subExpanded[sub.id] : false) }))) : (_jsx("div", { className: "w-6 h-6 invisible" }))] }), sub.id), subExpanded &&
                                                    subExpanded[sub.id] &&
                                                    (sub.childTree || []).map((child) => (_jsx("div", Object.assign({ className: classnames('flex items-center h-7.5 gap-2 text-sm px-3 cursor-pointer'), "data-testid": "merchant-category-third", onClick: () => handleClick(child, 'third') }, { children: _jsx("span", Object.assign({ className: classnames('text-[#66666] text-1xs line-clamp-1', {
                                                                'text-primary': filter.categoryIds === child.id
                                                            }) }, { children: child.name })) }), child.id)))] }));
                                    }) }))] }, item.id));
                    }) }))] }));
};
CategoryFilter.defaultProps = {};
