var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CategoryApi } from '@common/api/category';
import { ProductListApi } from '@common/api/product-list';
import { merchantInfoState } from '@common/application/store/product-detail';
import { productListBanners } from '@common/application/store/product-list';
import { useAsyncEffect } from 'ahooks';
import { last, uniqueId } from 'lodash-es';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { ProductListContext } from '../context/product-list';
import { checkQueryDep } from '../utils/check-query-dep';
export function useSetInitialBanners() {
    const { query, events } = useRouter();
    const { pageType, products: productListData } = useContext(ProductListContext);
    const setProductListBanners = useSetRecoilState(productListBanners);
    const setMerchantInfo = useSetRecoilState(merchantInfoState);
    function getBrandBanners() {
        var _a;
        const brand = (_a = productListData.resultBrand) === null || _a === void 0 ? void 0 : _a.find((item) => item.id === query.brandId);
        if ((brand === null || brand === void 0 ? void 0 : brand.productBrandVo) && (brand.productBrandVo.pcBanner || brand.productBrandVo.mbBanner)) {
            return [
                {
                    id: uniqueId(),
                    pc: brand.productBrandVo.pcBanner || null,
                    mobile: brand.productBrandVo.mbBanner || null,
                    target: brand.productBrandVo.targetUrl || null
                }
            ];
        }
        return [];
    }
    function getCategorysBanners() {
        return __awaiter(this, void 0, void 0, function* () {
            const categoryId = last(query.path);
            const categorys = yield CategoryApi.getListById(categoryId);
            const category = categorys === null || categorys === void 0 ? void 0 : categorys.find((item) => item.id === categoryId);
            if (category && (category.pcBannerPicturePath || category.bannerPicturePath)) {
                return [
                    {
                        id: uniqueId(),
                        pc: category.pcBannerPicturePath || null,
                        mobile: category.bannerPicturePath || null,
                        target: category.bannerTargetUrl || null
                    }
                ];
            }
            return [];
        });
    }
    const handleRouteChange = () => __awaiter(this, void 0, void 0, function* () {
        switch (pageType) {
            case 'brand':
                setProductListBanners(getBrandBanners());
                break;
            case 'displayCategory':
                setProductListBanners(yield getCategorysBanners());
                break;
            case 'merchant': {
                const merchantInfo = yield ProductListApi.getCooperationMerchant({
                    id: query.merchantId
                });
                setMerchantInfo(merchantInfo);
                setProductListBanners((merchantInfo === null || merchantInfo === void 0 ? void 0 : merchantInfo.banner) || []);
                break;
            }
            default:
                setProductListBanners([]);
        }
    });
    // 合作商户/品牌/分类 支持 banner
    /**
     * router.events监听不到页面首页刷新
     * 需要useEffect触发一次
     */
    useAsyncEffect(() => __awaiter(this, void 0, void 0, function* () {
        yield handleRouteChange();
    }), []);
    useEffect(() => {
        // 监听路由变化事件
        events.on('routeChangeComplete', handleRouteChange);
        // 清理事件监听
        return () => {
            events.off('routeChangeComplete', handleRouteChange);
        };
    }, [events, checkQueryDep(query, pageType), pageType]);
}
