import { DisplayLocation } from '@common/types/popup-ad';
import { last } from 'lodash-es';
import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';
import { ProductListContext } from '../context/product-list';
import { checkQueryDep } from '../utils/check-query-dep';
export function usePopupAd() {
    const { query } = useRouter();
    const { pageType } = useContext(ProductListContext);
    return useMemo(() => {
        switch (pageType) {
            case 'brand':
                return {
                    id: query.brandId,
                    displayLocation: DisplayLocation.SPECIFIC_BRAND
                };
            case 'displayCategory':
                return {
                    id: last(query.path),
                    displayLocation: DisplayLocation.SPECIFIC_CATEGORY
                };
            case 'merchant':
                return {
                    id: query.merchantId,
                    displayLocation: DisplayLocation.SPECIFIC_MERCHANT
                };
        }
        return {};
    }, [checkQueryDep(query, pageType), pageType]);
}
