var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CategoryApi } from '@common/api/category';
import { categoryBreadcrumb } from '@common/application/store/product-list';
import { useMount } from 'ahooks';
import { last, uniqueId } from 'lodash-es';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useContext, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { ProductListContext } from '../context/product-list';
import { checkQueryDep } from '../utils/check-query-dep';
export function useBreadcrumb() {
    const { t } = useTranslation();
    const { query, events } = useRouter();
    const { pageType } = useContext(ProductListContext);
    const [breadcrumb, setBreadcrumb] = useRecoilState(categoryBreadcrumb);
    const name = ((pageType === 'search' ? query.keywords || query.q : query.name) || '');
    const breadcrumbWithHome = useMemo(() => [
        {
            id: uniqueId(),
            path: [],
            type: 'home',
            name: t('common:home')
        },
        ...breadcrumb
    ], [breadcrumb]);
    function getBreadcrumb() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (pageType === 'displayCategory') {
                return CategoryApi.getBreadcrumb(last(query.path));
            }
            if (pageType === 'virtualCategory') {
                return ((_b = (_a = query.path) === null || _a === void 0 ? void 0 : _a.slice(0, -2).map((name) => ({
                    id: uniqueId(),
                    path: [],
                    type: pageType,
                    name
                }))) !== null && _b !== void 0 ? _b : []);
            }
            return [
                {
                    id: uniqueId(),
                    path: [],
                    type: pageType,
                    name
                }
            ];
        });
    }
    const handleRouteChange = () => {
        getBreadcrumb().then(setBreadcrumb);
    };
    /**
     * router.events监听不到页面首次刷新
     * 需要useEffect触发一次
     */
    useMount(() => {
        handleRouteChange();
    });
    /**
     * 搜索plp，query变化时，需要更新面包屑
     */
    useEffect(() => {
        if (pageType === 'search') {
            handleRouteChange();
        }
    }, [name, pageType]);
    useEffect(() => {
        // 监听路由变化事件
        events.on('routeChangeComplete', handleRouteChange);
        // 清理事件监听
        return () => {
            events.off('routeChangeComplete', handleRouteChange);
        };
    }, [events, checkQueryDep(query, pageType), pageType, name]);
    return [breadcrumb, breadcrumbWithHome];
}
