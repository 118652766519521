var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ProductListApi } from '@common/api/product-list';
import { pageTitleParams } from '@common/application/store/common';
import { prodcutListInitPrice, prodcutListPageTypeState, productList, productListBaseFilter, productListParams, productListSourceParams, storeParams2ApiParams, urlToParams } from '@common/application/store/product-list';
import { emitPopState, usePlpFilterUpdateListener } from '@common/application/utils/event';
import { ServerSide } from '@common/application/utils/server-side';
import { PopupAd } from '@common/components/common/popup-ad';
import { useAsyncEffect, useMount } from 'ahooks';
import { get, isEmpty, last, omit } from 'lodash-es';
import { useRouter } from 'next/router';
import { useContext, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { ProductListContext } from '../context/product-list';
import { useBreadcrumb } from '../hooks/use-breadcrumb';
import { usePopupAd } from '../hooks/use-popup-ad';
import { useSetInitialBanners } from '../hooks/use-set-initial-banners';
import { useSyncUrlTitle } from '../hooks/use-sync-url-title';
import { getServerSideName } from '../utils/get-server-side-name';
import { getSourceParams } from '../utils/get-source-params';
import { MobileBasePage } from './mobile-base-page';
import { WebBasePage } from './web-base-page';
const ProductListPage = ({ CustomLayout, recoilState }) => {
    const router = useRouter();
    const { pageType, sourceParams, dispatch } = useContext(ProductListContext);
    const setProductListBaseFilter = useSetRecoilState(productListBaseFilter);
    const setProductListParams = useSetRecoilState(productListParams);
    const setProductList = (params) => dispatch({ type: 'setState', payload: { products: params } });
    const setPageTitleParams = useSetRecoilState(pageTitleParams);
    const blockUpdate = useRef(true);
    const [breadcrumb, breadcrumbWithHome] = useBreadcrumb();
    const popupAd = usePopupAd();
    useSyncUrlTitle(breadcrumb);
    useSetInitialBanners();
    /**
     * 处理登录回来后token未刷新,因为sso是客服端渲染，
     * 首次进入只能在客户端拿到正确的token，只需要首次渲染时重新请求
     * plp数据
     */
    useMount(() => __awaiter(void 0, void 0, void 0, function* () {
        const list = yield ProductListApi.getProductList(Object.assign(Object.assign({}, storeParams2ApiParams(urlToParams(router.query, pageType))), sourceParams));
        setProductList(list);
        setPageTitleParams(recoilState === null || recoilState === void 0 ? void 0 : recoilState.pageTitleParams);
    }));
    /**
     * 防止plp筛选项二次更新
     */
    usePlpFilterUpdateListener(() => {
        blockUpdate.current = false;
    });
    useAsyncEffect(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const params = urlToParams(router.query, pageType);
        if (blockUpdate.current) {
            const res = yield ProductListApi.getFilters(omit(storeParams2ApiParams(params), ['page', 'prePage']), {
                hideLoading: true
            });
            setProductListBaseFilter(res);
            setProductListParams(Object.assign(Object.assign({}, params), { brands: isEmpty(params.brandIds) ? [] : (_a = params.brandIds) === null || _a === void 0 ? void 0 : _a.split(','), brandIds: '' }));
        }
        blockUpdate.current = true;
        // 触发plp滚动到目标元素
        emitPopState();
    }), [router.query]);
    return (_jsxs(_Fragment, { children: [CustomLayout && (_jsxs(CustomLayout, Object.assign({ footerClassName: "plp-footer" }, { children: [_jsx("div", Object.assign({ className: "pc:block pad:block mobile:hidden" }, { children: _jsx(WebBasePage, { breadcrumb: breadcrumbWithHome }) })), _jsx("div", Object.assign({ className: "pc:hidden pad:hidden mobile:block h-full" }, { children: _jsx(MobileBasePage, {}) }))] }))), popupAd && _jsx(PopupAd, Object.assign({}, popupAd))] }));
};
function getProductList(params, ctx) {
    return __awaiter(this, void 0, void 0, function* () {
        if (ctx.query.keywords ||
            ctx.query.q ||
            ctx.query.brandId ||
            ctx.query.promotionId ||
            ctx.query.merchantId ||
            last(ctx.query.path)) {
            return ProductListApi.getProductList(Object.assign(Object.assign({}, params), { page: (ctx.query.page ? ctx.query.page : 1) }), { ctx });
        }
        return {
            total: 0,
            page: 1,
            result: [],
            webResult: [],
            totalPage: 0,
            resultPrice: { minPrice: '0', maxPrice: '0' },
            resultBrand: []
        };
    });
}
ProductListPage.getServerSideProps = (ctx, pageType) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const i18nProps = yield ServerSide.getTranslations(ctx, ['common', 'product-list']);
        const { query } = yield ServerSide.getServerSideProps(ctx);
        // 路径转接口请求参数
        const params = urlToParams(query, pageType);
        const sourceParams = getSourceParams(ctx, pageType);
        const productListData = yield getProductList(Object.assign(Object.assign({}, storeParams2ApiParams(params)), sourceParams), ctx);
        const name = getServerSideName(ctx, pageType);
        return {
            props: Object.assign(Object.assign({}, i18nProps), { recoilState: {
                    [pageTitleParams.key]: {
                        searchQuery: query.keywords || query.q || null,
                        brandName: name || null,
                        categoryName: name || null
                    },
                    [productListSourceParams.key]: sourceParams,
                    [productList.key]: productListData,
                    [prodcutListInitPrice.key]: productListData.resultPrice,
                    [prodcutListPageTypeState.key]: pageType
                }, pageSEO: {
                    title: name,
                    keywords: name,
                    description: {
                        key: 'product-list:metaData.description',
                        options: {
                            categoryName: name
                        }
                    },
                    isPlp: true
                } })
        };
    }
    catch (e) {
        const errorData = get(e, 'data', {});
        if (errorData && errorData.errCode === 500009) {
            return {
                redirect: {
                    destination: '/',
                    permanent: false
                }
            };
        }
        return ServerSide.createException(ctx, e);
    }
});
ProductListPage.defaultProps = {
    CustomLayout: undefined
};
export default ProductListPage;
