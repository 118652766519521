import { classNames } from '@common/application/utils/classnames';
import { confirm } from '@common/application/utils/ui/confirm';
import { InnerModal } from '@common/application/utils/ui/edit-delivery-by-modal';
import { Toast } from '@common/application/utils/ui/toast';
import { Breadcrumb } from '@common/components/common/category-breadcrumb';
import { ConsigneeForm } from '@common/components/common/consignee-form';
import { DeliveryTag } from '@common/components/common/delivery-tag';
import DownLoadNotice from '@common/components/common/download-notice';
import { EmptyList } from '@common/components/common/empty-list';
import { Exceptional } from '@common/components/common/exceptional';
import { DImage } from '@common/components/common/image';
import { ImageViewer } from '@common/components/common/image-viewer';
import { Loading } from '@common/components/common/loading';
import { PickupLimitModal } from '@common/components/common/pickup-limit-modal';
import { PopupAd } from '@common/components/common/popup-ad';
import { LivingGoodsCard } from '@common/components/common/product-card-living';
import { PromotionLabel } from '@common/components/common/product-card-living/promotion-label';
import { ScrollToTop } from '@common/components/common/scroll-to-top';
import Select from '@common/components/common/select';
import { TextCollapse } from '@common/components/common/text-collapse';
import { RightArrowLine } from '@common/components/icon/right-arrow-line';
import { LANGUAGE_VALUES } from '@common/constants/language';
import { ToolBox } from '@common/pages/my-account/index/component/toolbox';
import { CategoryFilter } from '@common/pages/product-list/components/category-filter';
import { FilterTitle } from '@common/pages/product-list/components/filter-title';
import { MobileFilter } from '@common/pages/product-list/components/mobile-filter';
import { PriceFilter } from '@common/pages/product-list/components/price-filter';
import ProductListPage from '@common/pages/product-list/components/product-list-page';
import { Sort } from '@common/pages/product-list/components/sort';
import { i18n } from 'next-i18next';
import { createElement } from 'react';

import { CustomLayout } from '@/components/common/custom-layout';
import { IconArrow } from '@/components/icon/arrow';
import {
  ICON_PROMOTION_LABEL,
  IMG_DOWNLOAD_LOGO,
  IMG_ICON_LIVING_LOGO,
  IMG_ICON_NETWORK,
  IMG_ICON_NO_EVALUATION,
  IMG_ICON_TO_TOP,
  IMG_LOADING
} from '@/resources/images';
import { BlackLineIcon } from '@/resources/sprites-images/black-line-icon';
import { ColorFillIcon } from '@/resources/sprites-images/color-fill-icon';
import { ColorLineIcon } from '@/resources/sprites-images/color-line-icon';
import { ProductFilterIcon } from '@/resources/sprites-images/product-filter-icon';
import { ProductHighlightIcon } from '@/resources/sprites-images/product-highlight-icon';
import { ToolBoxIcon } from '@/resources/sprites-images/tool-box-icon';
import { WhiteLineIcon } from '@/resources/sprites-images/white-line-icon';
import { WhiteOutlineIcon } from '@/resources/sprites-images/white-outline-icon';

const getLanguageImage = () => {
  const imageMap = new Map([
    [LANGUAGE_VALUES.TC, 'tc'],
    [LANGUAGE_VALUES.EN, 'en'],
    [LANGUAGE_VALUES.CN, 'cn']
  ]);

  if (i18n?.language) {
    return imageMap.get(i18n.language as LANGUAGE_VALUES)!;
  }
  return 'tc';
};
DownLoadNotice.defaultProps = {
  logoImage: IMG_DOWNLOAD_LOGO
};

Loading.defaultProps = {
  image: IMG_LOADING,
  mask: true,
  containerClassName: 'bg-transparent'
};

Breadcrumb.defaultProps = {
  arrowIcon: (
    <div className={classNames('w-6 h-6 flex items-center justify-center')}>
      <RightArrowLine className="w-4 h-4 text-black-3" />
    </div>
  )
};

DImage.defaultProps = {
  defaultImage: IMG_ICON_LIVING_LOGO
};

ScrollToTop.defaultProps = {
  className: 'bg-transparent',
  icon: <DImage className={classNames('w-11 h-11', 'pc:w-15 pc:h-15')} src={IMG_ICON_TO_TOP} />
};

confirm.success.defaultProps.icon = createElement(ColorFillIcon, {
  icon: 'success',
  className: classNames('w-12 h-12 mb-5', 'pc:mb-6 pc:w-16 pc:h-16')
});

confirm.error.defaultProps = {
  color: 'primary',
  icon: createElement(ColorFillIcon, {
    icon: 'error-light',
    className: classNames('w-12 h-12 mb-5 mt-6', 'pc:my-6 pc:w-16 pc:h-16')
  }),
  cancelProps: { className: 'min-w-23 h-10.5 pc:h-11' },
  confirmProps: { className: 'min-w-23 h-10.5 pc:h-11' }
};

EmptyList.defaultProps = {
  customImage: IMG_ICON_NO_EVALUATION
};

Exceptional.defaultProps = {
  CustomLayout,
  image: IMG_ICON_NETWORK,
  imageClassName: classNames('mt-10', 'pc:mt-15'),
  textClassName: classNames('mt-2')
};

DeliveryTag.defaultProps = {
  homeDeliveryBgColor: 'bg-green-2',
  storePickupBgColor: 'bg-primary'
};

ImageViewer.defaultProps = {
  closeIcon: <WhiteLineIcon className={classNames('w-full h-full')} icon="x" />
};

PickupLimitModal.defaultProps = {
  icon: <ColorFillIcon className={classNames('w-8 h-8', 'pc:w-12 pc:h-12')} icon="error-light" />,
  hideMerchantName: true
};

PopupAd.defaultProps = {
  icon: <WhiteLineIcon className="w-full h-full" icon="x" />
};

// @ts-ignore
Select.defaultProps = {
  renderSolideArrow: (visible: boolean) => (
    <WhiteLineIcon
      icon={visible ? 'bottom-triangle' : 'top-triangle'}
      className="w-4 h-4 ml-0.5 text-black-3"
    />
  )
};

Toast.defaultProps = {
  successIcon: createElement(ColorFillIcon, {
    className: 'w-16 h-16 mb-8',
    icon: 'success-light'
  }),
  errorIcon: createElement(ColorFillIcon, {
    className: 'w-16 h-16 mb-8',
    icon: 'error-light'
  })
};

InnerModal.defaultProps = {
  ContentComponent: ConsigneeForm,
  cancelProps: { className: classNames('pc:min-w-39 bg-black-6 border-0') },
  confirmProps: { className: classNames('pc:min-w-39 bg-primary border-0') }
};

LivingGoodsCard.defaultProps = {
  favoriteIcon: (favorite) => {
    return favorite
      ? createElement(ColorFillIcon, { className: classNames('w-7 h-7'), icon: 'like' })
      : createElement(BlackLineIcon, { className: classNames('w-7 h-7'), icon: 'like' });
  },
  cartIcon: createElement(WhiteLineIcon, {
    className: classNames('w-full h-full'),
    icon: 'cart'
  }),
  productHightIcon: createElement(ProductHighlightIcon, {
    className: classNames('absolute top-0 left-0 w-full h-full'),
    icon: getLanguageImage()
  })
};

ProductListPage.defaultProps = {
  CustomLayout
};

FilterTitle.defaultProps = {
  leftIcon: createElement(BlackLineIcon, {
    className: classNames('w-4 h-4'),
    icon: 'reset'
  })
};

PriceFilter.defaultProps = {
  searchIcon: createElement(BlackLineIcon, {
    className: classNames('w-4 h-4'),
    icon: 'search'
  })
};

Sort.defaultProps = {
  solideArrow: false
};

MobileFilter.defaultProps = {
  filterIcon: () => {
    return createElement(ProductFilterIcon, {
      className: classNames('w-4 h-4 ml-1'),
      icon: 'filter-default'
    });
  }
};

PromotionLabel.defaultProps = {
  imageSrc: ICON_PROMOTION_LABEL
};

CategoryFilter.defaultProps = {
  blackLineIcon: createElement(BlackLineIcon, {
    className: classNames('w-5 h-5'),
    icon: 'category-close'
  }),
  iconArrow: (expanded) => {
    return createElement(IconArrow, {
      className: classNames({ 'rotate-180': expanded }, 'text-black-1')
    });
  }
};

TextCollapse.defaultProps = {
  trigger: (open) =>
    open ? (
      <button className="text-primary text-xs">
        {i18n?.t('common:collapse') as string}
        <ColorLineIcon
          className="inline-block mt-0.5 w-3 h-3 align-top rotate-180"
          icon="down-arrow"
        />
      </button>
    ) : (
      <button className="text-primary text-xs">
        {i18n?.t('common:expand') as string}
        <ColorLineIcon className="inline-block mt-0.5 w-3 h-3 align-top" icon="down-arrow" />
      </button>
    )
};

ToolBox.defaultProps = {
  icon: (iconName) => (
    <ToolBoxIcon className={classNames('w-6 h-6', 'pc:w-9 pc:h-9')} icon={iconName} />
  )
};
