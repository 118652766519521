import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PRODUCT_LIST_PAGE_SIZE } from '@common/api/product-list';
import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { merchantInfoState } from '@common/application/store/product-detail';
import { productListBaseFilter, useSearchProductList } from '@common/application/store/product-list';
import { classNames } from '@common/application/utils/classnames';
import { Breadcrumb } from '@common/components/common/category-breadcrumb';
import { Pagination } from '@common/components/common/pagination';
import { TextCollapse } from '@common/components/common/text-collapse';
import { ROUTE_MAP } from '@common/constants/route-map';
import { isEmpty } from 'lodash-es';
import { useRouter } from 'next/router';
import qs from 'qs';
import React, { useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ProductListContext } from '../context/product-list';
import { Banner } from './banner';
import { NotProduct, ProductList } from './base-component';
import { Sort } from './sort';
import { WebFilter } from './web-filter';
export const WebBasePage = ({ breadcrumb, className, paginationClassName, breadcrumbClassName }) => {
    const { products } = useContext(ProductListContext);
    const { pc } = useBreakpoint();
    const [curPage, setCurPage] = useState(1);
    const { search } = useSearchProductList();
    const router = useRouter();
    const filterOptions = useRecoilValue(productListBaseFilter);
    const merchantInfo = useRecoilValue(merchantInfoState);
    useEffect(() => {
        const { asPath, query } = router;
        if (query.page) {
            setCurPage(Number(query.page));
        }
        else if (asPath && asPath.split('?')[1]) {
            const parseQuery = qs.parse(asPath.split('?')[1]);
            setCurPage(Number(parseQuery.page ? parseQuery.page : 1));
        }
        else {
            setCurPage(Number(1));
        }
    }, [router]);
    return (_jsx("div", Object.assign({ className: classNames('w-full bg-black-8', className) }, { children: _jsxs("div", Object.assign({ className: "container mx-auto bg-transparent " }, { children: [_jsxs("div", Object.assign({ className: classNames('flex justify-between items-center h-16', breadcrumbClassName) }, { children: [_jsx(Breadcrumb, { breadcrumb: breadcrumb, className: classNames('mb-0 grid grid-flow-col') }), _jsx(Sort, { className: "flex-shrink-0" })] })), pc && _jsx(Banner, {}), (merchantInfo === null || merchantInfo === void 0 ? void 0 : merchantInfo.description) && router.route === ROUTE_MAP.MERCHANT && (_jsx(TextCollapse, Object.assign({ lineHeight: {
                        pc: 1.5,
                        mobile: 1.25
                    }, lineNumber: {
                        pc: 2,
                        mobile: 1
                    }, color: "black-1", className: "mb-4 w-full text-base text-black-1" }, { children: merchantInfo.description }))), _jsxs("div", Object.assign({ className: "grid grid-cols-12 gap-3" }, { children: [_jsx("div", Object.assign({ className: classNames('col-span-2') }, { children: !isEmpty(filterOptions) && _jsx(WebFilter, {}) })), _jsxs("div", Object.assign({ className: classNames('col-span-10 pb-4 overflow-hidden') }, { children: [products.total === 0 && _jsx(NotProduct, {}), _jsx("div", Object.assign({ className: "auto-rows-max auto-cols-max" }, { children: _jsx(ProductList, {}) })), _jsx("div", Object.assign({ className: classNames('p-5 pt-10 pb-6 flex items-center w-full justify-center', {
                                        hidden: products.total <= PRODUCT_LIST_PAGE_SIZE
                                    }, paginationClassName) }, { children: _jsx(Pagination, { current: curPage, total: products.total, pageSize: PRODUCT_LIST_PAGE_SIZE, onChange: (page) => {
                                            search({ filterType: 'page', _page: page });
                                        } }) }))] }))] }))] })) })));
};
