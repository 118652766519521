import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-11-03 10:34:21
 * @Description: 价格
 */
import { productListParams } from '@common/application/store/product-list';
import { classNames } from '@common/application/utils/classnames';
import { useResetPriceFilterListener, useUpdatePriceFilterListener } from '@common/application/utils/event';
import { Button } from '@common/components/common/button';
import { useUpdateEffect } from '@dch/design-widget-web/common/hooks/use-update-effect';
import { get } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import Slider from 'rc-slider';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { ProductListContext } from '../context/product-list';
import { MobileFilterContext } from './mobile-filter-context';
export const PriceFilter = ({ onChange, searchIcon, colseIcon }) => {
    const { t } = useTranslation(['product-list']);
    const { initPrice } = useContext(ProductListContext);
    const setFilter = useSetRecoilState(productListParams);
    const filterContext = useContext(MobileFilterContext);
    const [price, setPrice] = useState(initPrice);
    const searchPrice = useRef(true);
    useEffect(() => {
        setPrice(initPrice);
    }, [initPrice]);
    useUpdateEffect(() => {
        if (searchPrice.current) {
            setFilter((org) => (Object.assign(Object.assign({}, org), { price: { minPrice: price.minPrice, maxPrice: price.maxPrice } })));
        }
        searchPrice.current = true;
    }, [price]);
    useResetPriceFilterListener(() => {
        setPrice({ minPrice: initPrice.minPrice, maxPrice: initPrice.maxPrice });
    });
    /**
     * plp根据属性以及品牌筛选时，价格区间需要重新展示最大价格与最小价格
     * 但是不需要根据价格去查询
     */
    useUpdatePriceFilterListener((price) => {
        searchPrice.current = false;
        setPrice({ minPrice: price.minPrice, maxPrice: price.maxPrice });
    });
    return (_jsxs("div", Object.assign({ className: "grid grid-cols-1 text-sm" }, { children: [_jsxs("div", Object.assign({ className: classNames('flex items-center justify-between', 'mt-4 mb-5', 'pc:mb-2 pc:mt-0') }, { children: [_jsx("span", Object.assign({ className: "text-sm text-black-1" }, { children: t('price') })), _jsx("div", Object.assign({ className: classNames('block ', 'pc:hidden'), onClick: filterContext.closeDrawer }, { children: colseIcon }))] })), _jsxs("div", Object.assign({ className: "flex items-center justify-center h-7.5" }, { children: [_jsx("span", Object.assign({ className: classNames('mr-1 text-[#3D3D3D]') }, { children: "$" })), _jsx("div", Object.assign({ className: classNames('w-28 h-9', 'pc:w-15 pc:h-7') }, { children: _jsx("div", Object.assign({ className: "flex-1 h-full" }, { children: _jsx("div", Object.assign({ className: classNames('relative w-full flex flex-row items-center px-5 h-13.5 box-border rounded-sm border-solid border-gray bg-white border', 'h-11', 'pc:h-13.5', 'border-0 shadow-none h-full px-1 bg-[#F5F5F5] pc:h-full') }, { children: _jsx("input", { "data-testid": "price-min", maxLength: 6, className: "outline-none w-full h-full color-black text-sm bg-transparent text-black-1", placeholder: "", value: price.minPrice.toString(), onChange: ({ target: { value } }) => {
                                        if (/^(0|[1-9]\d*)(\.\d{0,2})?$|^$/.test(value)) {
                                            setPrice((prev) => (Object.assign(Object.assign({}, prev), { minPrice: value })));
                                        }
                                    } }) })) })) })), _jsx("span", Object.assign({ className: classNames('mx-1 text-[#3D3D3D]') }, { children: "-" })), _jsx("div", Object.assign({ className: classNames('w-28 h-9', 'pc:w-15 pc:h-7 pc:mr-1') }, { children: _jsx("div", Object.assign({ className: "flex-1 h-full" }, { children: _jsx("div", Object.assign({ className: classNames('relative w-full flex flex-row items-center px-5 h-13.5 box-border rounded-sm border-solid border-gray bg-white border', 'h-11', 'pc:h-13.5', 'border-0 shadow-none h-full px-1 bg-[#F5F5F5] pc:h-full') }, { children: _jsx("input", { "data-testid": "price-max", maxLength: 6, className: "outline-none w-full h-full color-black text-sm bg-transparent text-black-1", placeholder: "", value: price.maxPrice.toString(), onChange: ({ target: { value } }) => {
                                        if (/^(0|[1-9]\d*)(\.\d{0,2})?$|^$/.test(value)) {
                                            setPrice((prev) => (Object.assign(Object.assign({}, prev), { maxPrice: value })));
                                        }
                                    } }) })) })) })), _jsx(Button, Object.assign({ "data-testid": "price-filter-button", className: classNames('p-1.5 pb-0 bg-white border-black-7', 'hidden', 'pc:block'), onClick: () => {
                            onChange(price);
                        } }, { children: searchIcon }))] })), _jsx("div", Object.assign({ className: classNames('pr-1', 'pl-4', 'pc:pl-1') }, { children: _jsx(Slider, { className: classNames('mt-3 mb-5', 'pc:mt-2 pc:mb-4'), range: true, onChange: (value) => {
                        setPrice({ minPrice: get(value, `${[0]}`), maxPrice: get(value, `${[1]}`) });
                    }, value: [Number(price.minPrice) || 0, Number(price.maxPrice) || 0], min: Number(initPrice.minPrice) || 0, max: Number(initPrice.maxPrice) || 100 }) }))] })));
};
PriceFilter.defaultProps = {};
