import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { useResizeObserver } from '@common/application/hooks/use-resize-observer';
import { classNames } from '@common/application/utils/classnames';
import { unref } from '@common/application/utils/unref';
import { useToggle } from 'ahooks';
import { cloneElement, useEffect, useRef, useState } from 'react';
export const TextCollapse = (props) => {
    const { className, trigger, lineNumber = { pc: 1, mobile: 1 }, children, color, lineHeight } = props;
    const [open, openActions] = useToggle();
    const tirggerEl = trigger(open);
    const { pc } = useBreakpoint();
    const [overflow, setOverflow] = useState(false);
    const checkOverflowEl = useRef(null);
    useResizeObserver(() => {
        const el = unref(checkOverflowEl);
        if (el) {
            setOverflow(el.clientHeight < el.scrollHeight);
        }
    }, checkOverflowEl);
    useEffect(() => {
        const el = unref(checkOverflowEl);
        if (el) {
            setOverflow(el.clientHeight < el.scrollHeight);
        }
    }, [children]);
    return (_jsxs("div", Object.assign({ className: classNames('relative flex overflow-hidden', className) }, { children: [_jsxs("div", Object.assign({ className: classNames('relative overflow-hidden text-ellipsis'), style: {
                    maxHeight: !open
                        ? pc
                            ? `${lineHeight.pc * lineNumber.pc}rem`
                            : `${lineHeight.mobile * lineNumber.mobile}rem`
                        : ''
                } }, { children: [_jsx("div", { className: "float-right", style: {
                            height: pc ? `calc(100% - ${lineHeight.pc}rem)` : `calc(100% - ${lineHeight.mobile}rem)`
                        } }), overflow &&
                        cloneElement(tirggerEl, {
                            className: classNames(`relative clear-both float-right before:content-["..."] before:absolute before:-translate-x-full before:text-${color} before:-left-2 ml-[2em] bottom-0`, tirggerEl.props.className, { 'before:hidden': open }),
                            style: { height: pc ? `${lineHeight.pc}rem` : `${lineHeight.mobile}rem` },
                            onClick: openActions.toggle
                        }), children] })), _jsx("div", Object.assign({ ref: checkOverflowEl, className: "absolute opacity-0 left-0 right-0 overflow-y-scroll -z-1", style: {
                    maxHeight: pc
                        ? `${lineHeight.pc * lineNumber.pc}rem`
                        : `${lineHeight.mobile * lineNumber.mobile}rem`
                } }, { children: children }))] })));
};
TextCollapse.defaultProps = {};
