import { createPath } from '@common/application/utils/create-path';
import { ROUTE_MAP } from '@common/constants/route-map';
import { i18n } from 'next-i18next';

import {
  ICON_ALIPAY,
  ICON_BANK,
  ICON_MASTERCARD,
  ICON_PAYME,
  ICON_VISA,
  ICON_WECHATPAY
} from '@/resources/images';

import env from './env';

/**
 * topic 文章页判断
 */
export const generatePath = (id: string, name?: string) => {
  if (!id) return '';
  return createPath(ROUTE_MAP.TOPIC, { id, name });
};
export const GlobalConfig = () => {
  return {
    footerLink: [
      {
        title: 'common:footerLink.aboutUs',
        list: [
          {
            text: 'common:footerLink.companyIntroduction',
            href: generatePath(env.ABOUT_US, i18n?.t('common:footerLink.companyIntroduction'))
          },
          {
            text: 'common:footerLink.member',
            href: generatePath(env.MEMBER_CAMPAIGN, i18n?.t('common:footerLink.member'))
          },
          {
            text: 'common:footerLink.brand',
            href: generatePath(env.BRAND_CAMPAIGN, i18n?.t('common:footerLink.brand'))
          }
        ]
      },
      {
        title: 'common:footerLink.customerSupport',
        list: [
          {
            text: 'common:footerLink.deliveryServiceConditions',
            href: generatePath(
              env.DELIVERY_POLICY,
              i18n?.t('common:footerLink.deliveryServiceConditions')
            )
          },
          {
            text: 'common:footerLink.installationService',
            href: generatePath(
              env.INSTALLATION_SERVICE,
              i18n?.t('common:footerLink.installationService')
            )
          },
          {
            text: 'common:footerLink.FAQ',
            href: generatePath(env.FAQ, i18n?.t('common:footerLink.FAQ'))
          },
          {
            text: 'common:footerLink.concatUs',
            href: generatePath(env.CONCAT_US, i18n?.t('common:footerLink.concatUs'))
          },
          {
            text: 'common:footerLink.toolbox',
            href: env.TOOLBOX_SITE
          }
        ]
      },
      {
        title: 'common:footerLink.clause',
        list: [
          {
            text: 'common:footerLink.termsAndConditions',
            href: generatePath(
              env.TERMS_AND_CONDITIONS,
              i18n?.t('common:footerLink.termsAndConditions')
            )
          },
          {
            text: 'common:footerLink.servicePolicy',
            href: generatePath(env.SERVICE_POLICY, i18n?.t('common:footerLink.servicePolicy'))
          }
        ]
      }
    ],
    footerPayLogo: [
      {
        icon: 'visa',
        href: '',
        img: ICON_VISA
      },
      {
        icon: 'mostercard',
        href: '',
        img: ICON_MASTERCARD
      },
      {
        icon: 'wechatpay',
        href: '',
        img: ICON_WECHATPAY
      },

      {
        icon: 'payme',
        href: '',
        img: ICON_PAYME
      },
      {
        icon: 'alipay',
        href: '',
        img: ICON_ALIPAY
      },
      {
        icon: 'banktransfer',
        href: '',
        img: ICON_BANK
      }
      // {
      //   icon: IMG_AME,
      //   href: ''
      // },
      // {
      //   icon: IMG_UNIONPAY,
      //   href: ''
      // },
      // {
      //   icon: IMG_OCTPAY,
      //   href: ''
      // },
    ]
  };
};
