var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { useElementBounding } from '@common/application/hooks/use-element-bounding';
import { useSearchProductList } from '@common/application/store/product-list';
import { useResetPdpPageListener } from '@common/application/utils/event';
import LocalStorage from '@common/application/utils/storage';
import { POP_STATET } from '@common/constants/common';
import { useAsyncEffect, useEventListener } from 'ahooks';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { ProductListContext } from '../context/product-list';
const PAGE_SIZE = 20;
export const usePage = () => {
    const loading = useRef(false);
    const { products } = useContext(ProductListContext);
    const { mobile } = useBreakpoint();
    const { search } = useSearchProductList();
    // pdp返回标识
    const popState = LocalStorage.get(POP_STATET);
    // 记录从pdp返回的page，跟据这个pageNum调整页数
    const pageNum = useRef((products === null || products === void 0 ? void 0 : products.page) || 1);
    /**
     * plp展示卡片内容距离底部距离
     */
    const { bottom, isReady } = useElementBounding(() => document.getElementById('plp-container'));
    /**
     * 计算从pdp返回时，plp页面的总商品数量
     */
    const computedTotal = () => {
        if (popState && mobile) {
            pageNum.current = products.page;
            LocalStorage.set(POP_STATET, false);
            return pageNum.current * PAGE_SIZE;
        }
        return pageNum.current * PAGE_SIZE;
    };
    /**
     * 监听plp-container触底时，调用search查询
     */
    useAsyncEffect(() => __awaiter(void 0, void 0, void 0, function* () {
        if (isReady && mobile) {
            const distance = Math.floor(bottom) - window.outerHeight;
            if (distance <= 0 &&
                products.total > products.result.length &&
                products.totalPage > products.page &&
                !loading.current) {
                loading.current = true;
                yield search({
                    filterType: 'page',
                    _page: pageNum.current > 1
                        ? (pageNum.current += 1)
                        : Math.ceil(products.result.length / PAGE_SIZE) + 1
                });
            }
        }
    }), [bottom, isReady]);
    /**
     * 触顶时，调用search查询
     */
    const update = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (window.scrollY === 0 &&
            products.page > 1 &&
            !loading.current &&
            products.result.length < computedTotal() &&
            products.result.length < products.total &&
            mobile) {
            loading.current = true;
            yield search({
                filterType: 'page',
                _page: Math.floor((computedTotal() - products.result.length) / PAGE_SIZE),
                scrollType: 'top'
            });
        }
    }), [products]);
    /**
     * 监听滚动
     */
    useEventListener('scroll', update, { passive: true });
    /**
     * page更新时，重置loading，避免重复调用plp接口
     */
    useEffect(() => {
        loading.current = false;
    }, [products.page]);
    /**
     * 监听使用搜索时，重置pdp返回的页数
     */
    useResetPdpPageListener(() => {
        pageNum.current = 1;
    });
};
