import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const ToolBoxIcon = SpritesImage.createIcon({
  itemWidth: 120,
  itemHeight: 84,
  images,
  icons: {
    product: 1,
    buildings: 2,
    repair: 3,
    renewal: 4
  }
});
