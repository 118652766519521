import { prodcutListInitPrice, prodcutListPageTypeState, productList, productListSourceParams } from '@common/application/store/product-list';
import { useRouter } from 'next/router';
import { createContext, createElement, useEffect, useReducer } from 'react';
export const ProductListContext = createContext({
    renderType: 'living'
});
const productListReducer = (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case 'setState':
            return Object.assign(Object.assign({}, state), payload);
        case 'setProducts':
            return Object.assign(Object.assign({}, state), { products: payload });
        default:
            return state;
    }
};
const formatRecoilStateKey = (value) => {
    const nameMap = {
        [prodcutListPageTypeState.key]: 'pageType',
        [productListSourceParams.key]: 'sourceParams',
        [productList.key]: 'products',
        [prodcutListInitPrice.key]: 'initPrice'
    };
    const result = {};
    Object.keys(nameMap).forEach((key) => {
        result[nameMap[key]] = value[key];
    });
    return result;
};
export const ProductListContextProvider = (props) => {
    const { renderType, recoilState, children } = props;
    const router = useRouter();
    const [state, dispatch] = useReducer(productListReducer, Object.assign({ renderType }, formatRecoilStateKey(recoilState)));
    useEffect(() => {
        dispatch({
            type: 'setState',
            payload: Object.assign(Object.assign({}, formatRecoilStateKey(recoilState)), { renderType })
        });
    }, [router.query.path, router.query.q]);
    const productContext = Object.assign(Object.assign({}, state), { dispatch });
    return createElement(ProductListContext.Provider, Object.assign(Object.assign({}, props), { value: productContext }), children);
};
