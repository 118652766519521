import { pageTitleParams } from '@common/application/store/common';
import { createPath } from '@common/application/utils/create-path';
import { ROUTE_MAP } from '@common/constants/route-map';
import { isEmpty, last, omit } from 'lodash-es';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { ProductListContext } from '../context/product-list';
export function useSyncUrlTitle(breadcrumb) {
    var _a;
    const router = useRouter();
    const { pageType } = useContext(ProductListContext);
    const setPageTitleParams = useSetRecoilState(pageTitleParams);
    const name = ((_a = last(breadcrumb)) === null || _a === void 0 ? void 0 : _a.name) || '';
    function replacePath(basePath, params) {
        router.replace(createPath(basePath, params), '', {
            shallow: true
        });
    }
    useEffect(() => {
        if (isEmpty(breadcrumb)) {
            return;
        }
        setPageTitleParams({
            categoryName: name,
            brandName: name,
            searchQuery: (router.query.keywords || router.query.q)
        });
        switch (pageType) {
            case 'brand':
                replacePath(ROUTE_MAP.BRANCH, Object.assign(Object.assign({}, router.query), { name }));
                break;
            case 'displayCategory':
            case 'virtualCategory':
                replacePath(ROUTE_MAP.CATEGORY_LIST, Object.assign(Object.assign({}, omit(router.query, 'path')), { id: last(router.query.path), category: breadcrumb.map((item) => item.name) }));
                break;
            case 'merchant':
                replacePath(ROUTE_MAP.MERCHANT, Object.assign(Object.assign({}, router.query), { name }));
                break;
            case 'promotion':
                replacePath(ROUTE_MAP.PROMOTION, Object.assign(Object.assign({}, router.query), { name }));
                break;
        }
    }, [name, pageType]);
}
